html, body{
  min-height:100%;
  background: $white;
  font-weight: 400;
}
a, .btn, button, .myanimated{
  @include transition(all 300ms ease);
}
a{
  font-weight: 600;
  color: $link-color;
}
a:hover, a:focus{
  font-weight: 600;
  color: $first-base;
  text-decoration: $link-hover-decoration;
}
h1{
  margin:0.5em 0 1em 0;
  padding:0;
  color: $first-base;
}
h2, h3, h4, h5{
  margin:1.5em 0 1em 0;
  padding:0;
  color: $first-base;
}
h1{
  font-size: $font-size-h1;
}
h2{
  font-size: $font-size-h2;
}
h3{
  font-size: $font-size-h3;
}
h2.block-title{
  margin:0 0 0.5em 0;
  border-bottom: 1px solid #f4f5f6;
  font-size: 33pt;
  padding-top: 6.5pt;
  padding-bottom: 6.5pt;

  @media #{$tablet} {
    /*font-size: $font-size-h2;*/
  }
  @media #{$wide} {
    /*font-size: $font-size-h1;*/
  }
}

.page-node-910959,
.page-node-910960{

  h1,
  h2,
  h2.block-title{
    text-align: center;
  }

}

h1.page-header{
  padding-top: 6.5pt;
  margin-bottom: 15px;
  border-bottom: 1px solid #f4f5f6;
}

.title-header-img {
  max-height: 60px;
  max-width: 60px;
  padding: 0px 10px 5px 0px;
}

h2.block-sub-title{
  font-size: 24pt;
  border-bottom: 1px solid #f4f5f6;
}

.form-type-markup{
  h3{
    color: #000000;
    font-size: 14pt;
  }
}

.heading-confirm-message{
  font-size: 14pt;
  color: #d87400;
}

.body-confirm-message{
  color: #d87400;
  font-size: 12pt;
  margin-bottom: 10px;
}

table.webform-grid{
  margin-bottom: 0;
}

td.webform-grid-question{
  width: 50%;
}

.form-actions{
  margin-top: 30px;
}

.btn-success {
  background-color: #d87400;
  border-color: #bf6600;
}

.btn-success.active,
.btn-success:active,
.btn-success:hover,
.open>.dropdown-toggle.btn-success{
  background-color: #f28200;
  border-color: #f28200;
}

.btn-register {
  background-color: #991b1e;
  border-color: #991b1e;
  color: #fff;
}

.btn-register.active,
.btn-register:active,
.btn-register:hover,
.open>.dropdown-toggle.btn-register{
  background-color: lighten(#991b1e, 10%);
  background-color: lighten(#991b1e, 10%);
  color: #fff;
}

.webform-component--date.form-group,
.webform-component-date{
  min-height: 60px;
}

.day.form-control, .month.form-control, .year.form-control {
  width: inherit;
  float: left;
}

a.link-in-blue-bg{
  color: #00ffff;
}

a.link-in-blue-bg:hover, a.link-in-blue-bg:focus {
  color: #38e2e2;
}

.alert {
  h2{
    margin: 0.25em 0 0.5em 0;
  }
  p{
    margin-bottom: 0.25em;
  }
}

.accordion-notifications,
#accordion-pis{

  h2{
    margin: .25em 0 0.5em 0;
  }

  .fa-arrow-circle-o-down {
    float: right;
  }

  a.collapsed {
    .fa-arrow-circle-o-down::before {
      content: "\f01a";
    }
  }
  a {
    .fa-arrow-circle-o-down::before {
      content: "\f01b";
    }
  }
  a:hover{
    text-decoration: none;
  }
}

.accordion-notifications{
  a.notification-read-1{
    color: #808080;
  }
  a.btn, a.btn-primary{
    color: #fff;
  }
}

#admin-menu{
  font-size:12px;
  display:none;
  @media #{$tablet} {
    display:block;
  }
}
body.admin-menu{
  margin-top:0!important;
  @media #{$tablet} {
    /*margin-top:29px!important;*/
  }
}

.img-container{
  display: block;
  float: left;
  margin: 1.5em 0 2.5em 0;
}

.overlay{
  display:none;
  &.shadow{
    position:absolute;
    display:block;
    width:100%;
    height:100%;
    background:rgba(0, 0, 0, 0.5);
    z-index:1000;
  }
}

.not-logged-in{
  .header, .footer, .copyright{
    display: none;
  }
  .home-content {
    min-height: 100vh;
  }
}

.home-login{
  background: #ffffff;
  padding: 15px 30px;
  margin-top: 25vh;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);

  .btn-primary{
    float: right;
  }

  .user-login-logo{
    margin-bottom: 15px;
    position: relative;

    img{
      animation: fadein 4s;
      -moz-animation: fadein 4s;
      -webkit-animation: fadein 4s;
      -o-animation: fadein 4s;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    li:first-child {
      display: none;
    }

    a{
      font-weight: 300;
      padding: 8px 15px;
    }

    a:hover{
      color: #000000;
      font-weight: 300;
      text-decoration: none;
      background: #eeeeee;
      border-radius: 4px;
    }
  }
}

.home-page-logo{
  padding: 15px 0;
}

.home-content {
  min-height: 60vh;
  padding: 30px 0;
  background: url('/sites/default/files/page-background.jpg') center no-repeat;
  background-size: cover;
}

.home-login-links{
  padding: 15px 0 0;

  a{
    font-weight: 300;
    margin-left: 15px;
  }

  a:hover, a:focus, a:active{
    font-weight: 300;
    text-decoration: none;
  }

  .btn-default{
    margin-top: 0;
  }
}

.content.full-page-height{
  min-height: 100vh;
}

.welcome-message{
  padding: 2em 0 4em 0;
  background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%);
  background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(255,255,255,0) 100%);
  /*  filter: progid:DXImageTransform.Microsoft@include gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=0 );*/

  p{
    font-size: 20pt;
    color: #e8d025;
  }

  .weeks {
    font-size: 30pt;
  }
}

.logged-in {
  .home-content{
    .container {
      background: #fff;
      -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      padding-top: 15px;
      padding-bottom: 15px;
      .content-col{
        min-height: 70vh;
      }

      .home-page-footer{
        margin-top: 30px;
        border-top: 1px solid $first-base;
      }
    }
  }
}

.front.logged-in.user-role-maori,
.front.logged-in.user-role-pasifika{
  .home-content {
    .container {
      padding: 0;
    }
  }
}

.page-user-password,
.user-role-ip-registered-user,
.user-role-cc-registered-user{
  .content{
    min-height: 100vh;
  }
}

.user-role-ip-registered-user,
.user-role-cc-registered-user{
  .home-content{
    min-height: 100vh;
  }
}


.maori-home-screen{
  background: url("/sites/default/files/maori-splash.jpg") center no-repeat;
  background-size: cover;
  min-height: 70vh;

  #home-page-button-container{
    margin-top: 40vh;

    .btn{
      width: 100%;
      opacity: 0.9;
      font-size: 18pt;

      span{
        font-size: 12pt;
        font-style: italic;
        font-weight: 300;
      }
    }

    .btn:before {
      content: "";
      width: 50px;
      height: 50px;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("/sites/default/files/title-maori-icon-hinengaro.png");
    }

    #maori-wairua{
      .btn:before{
        background-image: url("/sites/default/files/title-maori-icon-wairua.png");
      }
    }
    #maori-tinana{
      .btn:before{
        background-image: url("/sites/default/files/title-maori-icon-tinana.png");
      }
    }
    #maori-whanau{
      .btn:before{
        background-image: url("/sites/default/files/title-maori-icon-whanau.png");
      }
    }
    #maori-te-hapai-oranga{
      .btn:before{
        background-image: url("/sites/default/files/title-maori-icon-tehapaioranga.png");
      }
    }
  }
}

.pasifika-home-screen{
  background: url("/sites/default/files/pasifika-original.jpg") center no-repeat;
  background-size: cover;
  min-height: 70vh;

  #home-page-button-container{
    margin-top: 40vh;

    .btn{
      width: 100%;
      opacity: 0.9;
      font-size: 18pt;

      span{
        font-size: 12pt;
        font-style: italic;
        font-weight: 300;
      }
    }

    .btn:before {
      content: "";
      width: 50px;
      height: 50px;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("/sites/default/files/title-pasifika-icon-journey.png");
    }

    #pasifika-spiritual{
      .btn:before{
        background-image: url("/sites/default/files/title-pasifika-icon-spiritual.png");
      }
    }
    #pasifika-physical{
      .btn:before{
        background-image: url("/sites/default/files/title-pasifika-icon-physical.png");
      }
    }
    #pasifika-family{
      .btn:before{
        background-image: url("/sites/default/files/title-pasifika-icon-family.png");
      }
    }

    #pasifika-mental{
      .btn:before{
        background-image: url("/sites/default/files/title-pasifika-icon-mental.png");
      }
    }
    #pasifika-other{
      .btn:before{
        background-image: url("/sites/default/files/title-pasifika-icon-other.png");
      }
    }

  }
}


.page-user-password {
  .tabs--primary,
  .breadcrumb{
    display: none;
  }
  /*
    .btn-default.form-submit{
      float: right;
    }
  */
}

/*
.form-submit{
  float: right;
}
*/

.terms_of_use{
  max-height: 350px;
  overflow: auto;
  border: 1px solid #ccc;
  padding: 15px;
}


.btn-primary,
.btn-default{
  color: #ffffff;
  margin-top: 15px;
  background-color: $second-base;
  border-color: darken($second-base, 5%);
}

.btn-primary.zero-top-margin,
.btn-default.zero-top-margin{
  margin-top: 0;
}

.form-actions,
.view-id-show_my_groups{
  .btn.btn-primary{
    margin-top: 0;
  }
}

.modal-footer {
  .btn,
  .btn-danger,
  .btn-primary,
  .btn-default {
    margin-top: 0;
    font-weight: 300;
  }

  .btn-danger:hover,
  .btn-danger:active,
  .btn:hover,
  .btn:active{
    font-weight: 300;
  }
}

.modal-body-main-text{
  color: #000000;
  margin: 0.5em 0;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active,
.btn-default:hover, .btn-default:focus, .btn-default:active,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover{
  font-weight: 400;
  color: #ffffff;
  background-color: lighten($second-base, 5%);
  border-color: lighten($second-base, 5%);
}

.pagination>.active>a,
.pagination>.active>span{
  background-color: $second-base;
  border-color: darken($second-base, 5%);
}

.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span:focus,
.pagination>.active>span:hover{
  background-color: lighten($second-base, 5%);
  border-color: lighten($second-base, 5%);
}

.form-control:focus {
  border-color: $second-base;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(216, 116, 0, 0.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(216, 116, 0, 0.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(216, 116, 0, 0.6);
}

.header{
  @include transition(all 300ms ease);
  color:$white;
  display:block;
  position:fixed;
  width:100%;
  min-height:100%;
  z-index: 1001;
  left:-100%;
  top:0;
  padding:0;
  .header_wrapper{
    @include transition(all 300ms ease);
    position: relative;

    width: calc(100% - 40px);
    min-height:100%;
    left:-100%;
  }
  .close{
    @include transition(all 300ms ease);
    color:$white;
    font-size:16pt;
    width:40px;
    height:40px;
    background:$second-base;
    position: absolute;
    top:0;
    right:100%;
    padding:4px 10px;
    z-index:9999;
    @include box-shadow(inset 0px 0px 10px rgba(0, 0, 0, 0.5));
    cursor: pointer;
    opacity:1;
    font-weight:normal;
    padding-top:0.35em;
  }
  &.open{
    left:0;
    top:0;
    .header_wrapper{
      left:0;
      top: 0;

      .topbar {
        #block-block-1 {
          margin-bottom: 0;
          padding: .25em 0;

          #header-logo img {
            max-width: 150px;
          }
        }

        #block-search-form {
          margin-top: 0.25em;
          padding-left: 0;
        }
      }
      
      .topmenu{
        background: $second-base;
      }
      
    }
    .close{
      right:0;
    }
  }
  .container{
    width:100%;
  }
  .topbar{
    background:$white;
    color:$black;
    padding:0.25em 0 0.25em 0;

    #header-logo{
      padding-left: 0;
      padding-right: 0;
    }

    #header-slogan{
      padding-left: 0;
      padding-right: 0;
      margin-top: 10px;

      h1{
        font-size: 14pt;
        letter-spacing: -1px;
        margin-top: 25px;
        color: $second-base;

        .size-one{
          font-size: 26pt;
          font-weight: 600;
          color: $first-base;
        }

        .size-two{
          font-weight: 500;
          font-size: 24pt;
          color: $second-base;
        }

        .size-three{
          font-size: 25pt;
          font-weight: 600;
          color: rgb(228, 64, 52);
        }

        .size-four{
          font-size: 25pt;
          font-weight: 500;
          color: $first-base;
        }
      }
    }

    #block-search-form {
      margin-top: 30px;
      padding-right: 0;

      .form-search {
        .form-text {
          background: #ffffff;
          @include border-radiuses (0, 0, 3px, 3px);
          padding: 3px 5px;
          color: $black;
          height: 34px;

          border: 1px solid $second-base;
        }
        .form-text:focus {
          -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(216, 116, 0, 0.6);
          -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(216, 116, 0, 0.6);
          box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(216, 116, 0, 0.6);
        }
        .btn-primary {
          margin-top: 0;
          background: $second-base;
          border-color: $second-base;
          color: $white;
          @include border-radiuses (3px, 3px, 0, 0);
        }
      }
    }
    .logo{
      padding:1em 0;
      margin:0.5em 0 2em 0;
      border-top:1px solid $gray-light;
      border-bottom:1px solid $gray-light;
      text-align:center;
      img{
        width:100%;
        height: auto;
        max-width: 300px;
        margin: 15px 0;
      }
    }
  }
  .topmenu{
    background-color:#d87400;
    border-bottom:3px solid #000;
    padding:0;

    .mainmenu{
      padding:0;
      .nav{
        padding:0;
        margin:0;
        text-align: center;
        li{
          display: block;

          a:before {
            content: "";
            width: 25px;
            height: 25px;
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
          a{
            display: block;
            color: white;
            margin:0;
            padding:1em 0.5em;
            text-transform: uppercase;
            font-weight: 300;
            border-bottom:none;
            font-size: 10pt;
          }
          a:hover, a:focus{
            background-color: $second-base-light;
            color: $black;
          }
        }
        .dropdown-menu{
          padding: 0;
          background-color: $second-base-light;
          border-radius: 0;

          li{
            display: block;
            width: 100%;
            a{
              width: 100%;
              background-color: $second-base-light;
              color: $black;
            }
            a:hover, a:focus{
              color: $first-base;
              background-color: $second-base-lighter;
            }
          }

        }

        @media (max-width: $screen-sm-min) {
          .dropdown-menu{
            width: 100%;
          }
        }
      }
    }
  }
  @media #{$normal} {
    z-index: 10;
    position:relative;
    width:100%;
    min-height:auto;
    left:0;
    background:$white;
    color:$gray-base;
    padding:0;
    .header_wrapper{
      position: relative;
      width:100%;
      min-height:auto;
      left:0;
      top: 0;
    }
    &:after{
      display:none;
    }
    .close{
      display: none;
    }
    a{
      color:$black;
    }
    .container{
      width:970px;
      position: relative;
    }
    .topbar{
      background-color:$white;
      color:$black;
      padding:0.25em 0 0.25em 0;
      .search{
        padding:0;
        display:block;
      }
      .social{
        text-align: right;
        padding:3px 0 0 15px;
      }

      .logo{
        padding:0 15px 0 0;
        margin:0;
        border-top:none;
        border-bottom:none;
        text-align:left;
      }
    }
    // Old style for main menu. Superfish_custom.scss is using for the menu
    .topmenu{
      background-color:#d87400;
      border-bottom:3px solid #000;
      padding:0;

      .mainmenu{
        padding:0;
        .nav{
          text-align: center;
          li{
            display: inline-block;

            a:before {
              content: "";
              width: 25px;
              height: 25px;
              display: inline-block;
              margin-right: 5px;
              vertical-align: middle;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }

            a#registration-menu:before{
              width: 0 !important;
              height: 25px;
            }

            a#hinegaro-menu:before{
              background-image: url("/sites/default/files/title-maori-icon-hinengaro-white.png");
            }

            a#tinana-menu:before{
              background-image: url("/sites/default/files/title-maori-icon-tinana-white.png");
            }

            a#wairau-menu:before{
              background-image: url("/sites/default/files/title-maori-icon-wairua-white.png");
            }

            a#whanau-menu:before{
              background-image: url("/sites/default/files/title-maori-icon-whanau-white.png");
            }

            a#te-hapai-oranga-menu:before{
              background-image: url("/sites/default/files/title-maori-icon-tehapaioranga-white.png");
            }

            a#pasifika-journey-menu:before{
              background-image: url("/sites/default/files/title-pasifika-icon-journey-white.png");
            }

            a#pasifika-spiritual-menu:before{
              background-image: url("/sites/default/files/title-pasifika-icon-spiritual-white.png");
            }

            a#pasifika-physical-menu:before{
              background-image: url("/sites/default/files/title-pasifika-icon-physical-white.png");
            }

            a#pasifika-family-menu:before{
              background-image: url("/sites/default/files/title-pasifika-icon-family-white.png");
            }

            a#pasifika-mental-menu:before{
              background-image: url("/sites/default/files/title-pasifika-icon-mental-white.png");
            }

            a#pasifika-other-menu:before{
              background-image: url("/sites/default/files/title-pasifika-icon-other-white.png");
            }

            a#notifications-menu:before{
              background-image: url("/sites/default/files/notifications_white.png");
            }

            // sub-menu icons
            a#current-rewards-menu:before, a#showcase-menu:before,
            a#pasifika-current-rewards-menu:before, a#pasifika-showcase-menu:before{
              background-image: url("/sites/default/files/title-icon-reward.png");
            }
            a#my-wero-menu:before,
            a#pasifika-my-goal-menu:before{
              background-image: url("/sites/default/files/title-icon-my-goal.png");
            }
            a#create-new-wero-menu:before{
              background-image: url("/sites/default/files/title-icon-goal.png");
            }
            a#pasifika-create-new-goal-menu:before{
              background-image: url("/sites/default/files/title-icon-new-goal.png");
            }
            a#pasifika-search-goal-menu:before{
              background-image: url("/sites/default/files/title-icon-search-goal.png");
            }
            a#mahi-tracker-menu:before, a#steps-tracker-menu:before,
            a#pasifika-activity-tracker-menu:before, a#pasifika-steps-tracker-menu:before{
              background-image: url("/sites/default/files/title-icon-activity-tracker.png");
            }
            a#manawatoa-menu:before,
            a#snacktivity-menu:before{
              background-image: url("/sites/default/files/title-icon-manawatoa.png");
            }
            a#mahi-ngatahi-menu:before,
            a#active-together-menu:before{
              background-image: url("/sites/default/files/title-icon-active-together.png");
            }
            a#activities-menu:before,
            a#pasifika-activities-menu:before{
              background-image: url("/sites/default/files/title-icon-activity.png");
            }
            a#recipe-menu:before,
            a#pasifika-recipe-menu:before{
              background-image: url("/sites/default/files/title-icon-recipe.png");
            }
            a#atua-menu:before{
              background-image: url("/sites/default/files/title-icon-atua.png");
            }
            a#fresh-produce-menu:before{
              background-image: url("/sites/default/files/title-icon-fresh-produce.png");
            }
            a#food-tracker-menu:before, a#water-tracker-menu:before,
            a#pasifika-food-tracker-menu:before, a#pasifika-water-tracker-menu:before{
              background-image: url("/sites/default/files/title-icon-food-tracker.png");
            }
            a#messages-menu:before,
            a#pasifika-messages-menu:before{
              background-image: url("/sites/default/files/title-icon-message.png");
            }
            a#support-service-menu:before,
            a#pasifika-support-service-menu:before{
              background-image: url("/sites/default/files/title-icon-support.png");
            }
            a#karakia-menu:before, a#create-new-karakia-menu:before{
              background-image: url("/sites/default/files/title-maori-icon-wairua.png");
            }
            a#pasifika-blessing-menu:before, a#pasifika-create-new-blessing-menu:before{
              background-image: url("/sites/default/files/title-pasifika-icon-spiritual.png");
            }
            a#add-friends-menu:before, a#group-for-friends-menu:before, a#my-groups-menu:before{
              background-image: url("/sites/default/files/title-maori-icon-whanau.png");
            }
            a#pasifika-add-friends-menu:before, a#pasifika-group-for-friends-menu:before, a#pasifika-my-groups-menu:before{
              background-image: url("/sites/default/files/title-pasifika-icon-family.png");
            }
            a#wai-maori-ngai-maori-menu:before{
              background-image: url("/sites/default/files/title-maori-icon-waimaori.png");
            }
            
            a{
              display: block;
              color: white;
              margin:0;
              padding:1em 0.5em;
              text-transform: uppercase;
              font-weight: 300;
              border-bottom:none;
              font-size: 9pt;
            }
            a:hover, a:focus{
              background-color: $second-base-light;
              color: $black;
            }
          }
          .dropdown-menu{
            padding: 0;
            background-color: $second-base-light;
            min-width: 245px;

            li{
              display: block;
              width: 100%;
              a{
                width: 100%;
                background-color: $second-base-light;
                color: $black;
              }
              a:hover, a:focus{
                color: $first-base;
                background-color: $second-base-lighter;
              }
            }

          }
        }
      }
    }
  }

  @media #{$wide} {

    .container{
      width:1170px;
    }
  }

  @media #{$xwide} {
    .container{
      width:1170px;
    }
  }
}


/* Style for Wero Pages */

.entityform {
  .submitted{
    display: none;
  }
}

.page-entityform,
.page-eform-submit-create-new-wero{
  .tabledrag-toggle-weight,
  .tabs--primary.nav{
    display: none;
  }
}

.user-role-participants{
  .tabs--primary.nav{
    display:none;
  }
}

.field-add-more-submit{
  float: right;
}

.goal-progress{
  margin: 15px 0;
  border: 3px solid $second-base;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  /*  transition: visibility 0s, opacity 2s linear;
    -webkit-transition: visibility 0s, opacity 2s linear;
    -moz-transition: visibility 0s, opacity 2s linear;
    -ms-transition: visibility 0s, opacity 2s linear;
    -o-transition: visibility 0s, opacity 2s linear;*/

  .row{
    margin-top: 15px;
  }

  .goal-title{
    h2{
      color: #000;
      margin: 0;
    }
  }

  .goal-completed-1{
    .fa.fa-star{
      display: inline-block;
    }
  }

  .goal-completed-0{
    .fa.fa-star{
      display: none !important;
    }
  }

  .goal-nga-tapuwae{
    p{
      text-align: right;
    }
  }

  a.goal-progress-link{
    color: #000000;
    text-decoration: none;
  }

  a.goal-progress-link:hover,
  a.goal-progress-link:focus,
  a.goal-progress-link:activ{
    color: #000000;
    text-decoration: none;
  }

  .progress-outer{
    width: 100%;
    max-width: 600px;
    height: 65px;
    margin: 15px auto;
    text-align: center;
    border: 1px solid #dcdcdc;
    background-image: url("/sites/default/files/goal_progress_background.png");
    background-size: 100% 75%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;

    .progress-inner{
      width: 0;
      min-width: 5%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      padding: 15px 5px 5px;
      border-radius: 20px;
      background-color: rgba(34, 139, 34, 0.8);

      transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
    }
  }
}


.reg-progress-outer{
  width: 100%;
  max-width: 600px;
  height: 40px;
  margin: 15px auto;
  text-align: center;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  display: none;

  .reg-progress-inner{
    color: #fff;
    width: 0;
    min-width: 5%;
    height: 100%;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 5px;
    background-color: rgba(34, 139, 34, 0.8);
    padding-top: 8px;

    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
  }
}

hr.general-separator{
  border-top: 1px dotted $second-base;
}

.goal-completed-filter-1,
.goal-expired-yes{
  display: none;
}

/* Style for Footsteps Page */

#block-wero-goal-wero-goal-nga-tapuwae-block{

  p.wero-completed{
    color: #c9cac4;
  }

  .row{
    margin-top: 15px;
  }

  .goal-title{
    h2{
      color: #000;
      margin: 0;
    }
  }

  .goal-completed-1{
    .fa.fa-star{
      display: inline-block;
    }
  }

  .goal-completed-0{
    .fa.fa-star{
      display: none !important;
    }
  }

  .progress-outer{
    width: 100%;
    max-width: 600px;
    height: 65px;
    margin: 15px auto;
    text-align: center;
    border: 1px solid #dcdcdc;
    background-image: url("/sites/default/files/goal_progress_background.png");
    background-size: 100% 75%;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;

    .progress-inner{
      width: 0;
      min-width: 5%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      padding: 15px 5px 5px;
      border-radius: 20px;
      background-color: rgba(34, 139, 34, 0.8);

      transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;
    }
  }

  .task-information-container{
    h3{
      font-size: 16pt;
      color: #000000;
      margin: .25em 0;

      span{
        font-weight: 600;
      }
    }

    th.day-heading.selected{
      color: $second-base;
    }

    label{
      font-weight: normal;
    }

    hr{
      border: 1px solid $first-base;
    }

    hr.task-separaor{
      border: 1px solid $second-base;
    }

  }
}

/* Style for Ngā Tapuwae Page */
.view-nga-tapuwae{

  .view-header{
    margin-bottom: 15px;
    h2{
      margin: 0.25em;
    }
  }

  .views-row{
    padding-bottom: 5px;
    padding: 5px 0;
    width: 100%;
    float: left;
  }

  .views-row-odd{
    background: #f4f5f6;
  }

  .action-button-container{
    text-align: right;
  }

  .btn, .btn-primary{
    margin-top: 0;
  }

  .footer-button-container{
    text-align: right;
    margin-top: 30px;
  }
}

/* Style for Wai Maori Ngai Maori Page */
#wai-maori-ngai-maori-page-header{
  background: url("/sites/default/files/banner-wai-maori-ngai-maori.jpg") center no-repeat;
  background-size: cover;
  width: 100%;
  height: 35vh;
  border-radius: 5px;
}

/* Style for Karakia Page */

.node-type-blessing{
  .nav-tabs{
    display: none;
  }
}

#karakia-page-header{
  background: url("/sites/default/files/matariki-star-cluster.jpg") center no-repeat;
  background-size: cover;
  width: 100%;
  height: 35vh;
  border-radius: 5px;
}

#blessing-page-header{
  background: url("/sites/default/files/church-new1.jpg") center top no-repeat;
  background-size: cover;
  width: 100%;
  height: 35vh;
  border-radius: 5px;
}

.karakia-author{
  margin-bottom: 10px;
  font-weight: 600;
}

.view-filters{
  width: 100%;
  border-bottom: 1px solid #f4f5f6;
  padding-bottom: 15px;
  float: left;

  .views-exposed-form{
    float: right;
  }
}
.views-exposed-widgets {
  margin-bottom: 0;
}

.blog-short-view {

  border-bottom: 1px solid #ddd;

  h2{
    margin: .25em 0;
  }

  img{
    margin-bottom: 10px;
    height: auto;
    display: inline-block;
  }

  .image-thumbnail{
    margin-bottom: 0;
    margin-right: 0;
    width: 100%;
    max-width: 120px;
  }

  a{
    font-weight: 400;
    display: inline-block;
    color: #ffffff;
    margin-bottom: 0.25em;
    background-color: $second-base;
    border-color: darken($second-base, 5%);
    border: 1px solid transparent;
    padding: 6px 12px;
    border-radius: 4px;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
  }

  a:hover, a:focus, a:active{
    text-decoration: none;
    font-weight: 400;
    color: #ffffff;
    background-color: lighten($second-base, 5%);
    border-color: lighten($second-base, 5%);
  }
}

.node-blessing {
  .field {
    margin-bottom: 15px;
  }
}

/* Style for Wero Pages */

.goal-action-links{
  padding-bottom: 15px;

  a{
    margin-left: 5px;
  }
}

/* Style for Rewards Pages */

.kete-list{

  list-style: none;
  width: 100%;
  float: left;
  padding-left: 0;

  li{
    display: inline-block;
    width: 100px;
    height: 100px;
    background: url("/sites/default/files/grey-kete.jpg") center no-repeat;
    background-size: cover;
    float: left;

    a{
      display: block;
      width: 100%;
      height: 100%;
      text-indent: -99999px;
      background: url("/sites/default/files/kete.jpg") center no-repeat;
      background-size: cover;
    }
  }
}

.user-role-pasifika .kete-list li {
  background: none;
}

.user-role-pasifika .kete-list li a {
  background-image: url("/sites/default/files/coconut-step5.png");
  background-size: cover;
}

.coconut-tree-lifecycle{

  .coconut-lc-block{
    width: 100%;
    height: 150px;
    max-width: 150px;

    background-size: cover;
    margin: 0 auto;

    a{
      display: block;
      width: 100%;
      height: 100%;
      text-indent: -99999px;
      background: url("/sites/default/files/kete.jpg") center no-repeat;
      background-size: cover;
    }
  }

  #coconut-lc-block-1 {
    background: url("/sites/default/files/coconut-lc-grey-step1.jpg") center no-repeat;
    background-size: cover;

    a{
      background: url("/sites/default/files/coconut-lc-step1.jpg") center no-repeat;
      background-size: cover;
    }
  }

  #coconut-lc-block-2 {
    background: url("/sites/default/files/coconut-lc-grey-step2.jpg") center no-repeat;
    background-size: cover;

    a{
      background: url("/sites/default/files/coconut-lc-step2.jpg") center no-repeat;
      background-size: cover;
    }
  }

  #coconut-lc-block-3 {
    background: url("/sites/default/files/coconut-lc-grey-step3.jpg") center no-repeat;
    background-size: cover;

    a{
      background: url("/sites/default/files/coconut-lc-step3.jpg") center no-repeat;
      background-size: cover;
    }
  }

  #coconut-lc-block-4 {
    background: url("/sites/default/files/coconut-lc-grey-step4.jpg") center no-repeat;
    background-size: cover;

    a{
      background: url("/sites/default/files/coconut-lc-step4.jpg") center no-repeat;
      background-size: cover;
    }
  }

  #coconut-lc-block-5 {
    background: url("/sites/default/files/coconut-lc-grey-step5.jpg") center no-repeat;
    background-size: cover;

    a{
      background: url("/sites/default/files/coconut-lc-step5.jpg") center no-repeat;
      background-size: cover;
    }
  }

}




.reward-list{

  list-style: none;
  float: left;
  padding-left: 0;

  li{
    display: inline-block;
    background-size: cover;
    float: left;
    div{
      width: 100%;
      height: 100%;

      a{
        display: block;
        width: 100%;
        height: 100%;
        text-indent: -99999px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("/sites/default/files/Tiki.jpg");
        background-size: contain;
        min-height: 300px;
      }

      a.Coconut{
        background-image: url("/sites/default/files/coconut-step5.png");
      }
      a.Whare{
        background-image: url("/sites/default/files/Whare.jpg");
      }
      a.Waka{
        background-image: url("/sites/default/files/Waka.jpg");
      }
      a.Taiaha{
        background-image: url("/sites/default/files/Taiaha.jpg");
      }
      a.Poi{
        background-image: url("/sites/default/files/Poi.jpg");
      }
      a.Pōhā{
        background-image: url("/sites/default/files/Pōhā.jpg");
      }
      a.Patu{
        background-image: url("/sites/default/files/Patu.jpg");
      }
      a.Pataka{
        background-image: url("/sites/default/files/Pataka.jpg");
      }
      a.Matau{
        background-image: url("/sites/default/files/Matau.jpg");
      }
      a.Kupenga{
        background-image: url("/sites/default/files/Kupenga.jpg");
      }
      a.Ko{
        background-image: url("/sites/default/files/Ko.jpg");
      }
      a.Kawakawa{
        background-image: url("/sites/default/files/Kawakawa.jpg");
      }
      a.Hinaki{
        background-image: url("/sites/default/files/Hinaki.jpg");
      }
      a.Harakeke{
        background-image: url("/sites/default/files/Harakeke.jpg");
      }
      a.Kumara{
        background-image: url("/sites/default/files/Kumara.png");
      }
      a.Kaimoana{
        background-image: url("/sites/default/files/Kaimoana.png");
      }
      a.Hue{
        background-image: url("/sites/default/files/Gourd.png");
      }
    }
  }

}

.grey{
  color: #c9cac4;
}

/* Style of Notification Page */
.notifications-list-item{

  .Friend.Rejected{
    display: none;
  }

}

.view-content{
  clear: both;
  float: left;
  width: 100%;
}


.mobile_header{
  display:block;
  padding:0.5em 15px;
  background:$second-base;
  color:$white;
  vertical-align: middle;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  @media #{$normal} {
    display:none;
  }
  .menu-btn{
    display: inline-block;
    font-size: 18pt;
    font-weight: normal;
    margin-right:0.5em;
    padding:0.25em;
    border:1px solid rgba(255, 255, 255, 0.33);
    @include border-radius(3px);
    width:40px;
    height:40px;
    text-align: center;
    cursor: pointer;
  }
  .header-title{
    display: inline-block;
    font-size: $font-size-large;
    line-height: 1;
    font-weight: 300;
    padding:0.25em 0;
  }
  img{
    float: right;
  }
}

#our-values{
  background: #2f6186;
  margin: 20px 0;
  color: #fff;
  padding-bottom: 15px;

  .row{
    text-align: center;
  }

  h3{
    margin: 10px 0 0;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 34px;
  }

  h4{
    text-transform: uppercase;
    margin: 15px 0 0;
    text-align: center;
    color: #fff;
    font-size: 16px;
  }

  p{
    font-size: 13px;
  }
}

.input-group-btn{
  .btn-primary{
    margin-top: 0;
  }
}

.search-results{

  li{
    font-size: 18px;

    p{
      font-size: 14px;
    }
  }

  .title{
    font-size: 18px;
  }

  .search-info{
    display: none;
  }
}

.footer{
  padding:2em 0;
  margin:0;
  background:$second-base-lighter;
  color:$black;
  border:none;
  min-height: 200px;

  .footer-logos{
    text-align: center;
    img{
      display: inline-block;
      padding: 15px;
    }
  }

  display: none;
}
.copyright{
  padding:1em 0;
  background:$second-base-lighter;
  color:$black;
  font-size: $font-size-small;
  text-align: center;

  p{
    margin-top: 0.25em;
  }
}

.content {
  padding: 1em 0;
  background: $white;
  background: url('/sites/default/files/page-background.jpg') center no-repeat;
  background-size: cover;
  min-height: 80vh;

  .content{
    background-image: none;
  }

  .container {
    min-height: 50vh;
    background: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.not-logged-in .content{
  min-height: 100vh;
}


.front {
  .content{
    min-height: 0;
    h2{
      font-size: 18pt;
    }

    .container {
      min-height: 0;
    }
  }
}

.breadcrumb{
  margin: 0;
  padding: 8px 0;
  font-size: 9pt;
  background-color: transparent;
}

/* Style for My Whanau Pages */

.view-id-my_whanau,
.friend-thumbnail-container,
.view-id-group_details{
  .thumbnail{
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.page-header-image-box{
  background-size: cover;
  width: 100%;
  height: 35vh;
  border-radius: 5px;
  text-indent: -99999px;
  margin-bottom: 30px;
}

#friends-page-header{
  background: url(/sites/default/files/family_maori.jpg) center top no-repeat;
}

#friends-page-header-pasifika{
  background: url(/sites/default/files/family_pasifika.jpg) center top no-repeat;
}

/* Style for Group Page */
.node-type-group-for-friends{
  .tabs--primary.nav-tabs{
    display: none;
  }

}

/* Style for Tracker */

.views-table{
  margin-top: 15px;
}

.total-steps-this-week{
  margin-top: 15px;
}

/* Style for Recipe */

.user-role-maori,
.user-role-pasifika {
  .tabledrag-toggle-weight-wrapper,
  .field-name-field-pictures {
    display: none;
  }
}


/* Style for Atua */
.atua-img {
  max-height: 200px;
  max-width: 200px;
}

.atua-column {
  float: left;
  width: 33.33%;
  text-align: center;
}

.atua-row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style for Seasonal Produce */
.row-click {
  td a { 
    display: block; 
    border: 1px solid black;
    padding: 16px; 
  }
}

.grid-col-width {
  min-width: 150px;
  padding: 8px;
}

/* Style for FAQs page */
.panel-group {
  .faq-panel {
    margin: 15px 0;
    border-color: #efc799;

    .faq-panel-heading {
      border-color: #efc799;
      background-color: #efc799;
      color: #000;
      transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      -moz-transition: all 300ms ease;
      -ms-transition: all 300ms ease;
      -o-transition: all 300ms ease;

      a:hover, a:focus, a:active{
        text-decoration: none;
        color: #000;
      }
    }

    .faq-panel-heading:hover {
      border-color: #d87400;
      background-color: #d87400;
    }
  }

  .more-less {
    float: right;
    color: #000;
  }
}


/* New Style for the feedback on Trello */

#field-m-wero-nga-tapuwae-values th:last-child {
  display: none;
}

.show-pasifika,
.show-maori {
  display: none;
}

.user-role-pasifika {
  .show-pasifika {
    display: inline-block;
  }
}

.user-role-maori {
  .show-maori {
    display: inline-block;
  }
}


.sf-menu li .main-menu-without-icon:before{
  width: 0;
  height: 25px;
}
.user-role-nihi-admin {
  #edit-preview.btn-default,
  #edit-submit.btn-default {
    margin-top: 0;
  }
}

.text-beside-login-btn{
  color: #fe0000;
}

.user-role-authenticated-user{
  .webform-submission{
    .webform-component--pis-box,
    .webform-component--pis-box-swipcs,
    .webform-component--pis-box-the-fono{
      display: none;
    }
  }
}

/* Animation - fade in */
@keyframes fadein {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
    opacity:0;
  }
  to {
    opacity: 1;
  }
}


@media (max-width: 560px) {
  #our-values h3 {
    font-size: 26px;
  }

  .home-login {
    margin-top: 80px;
  }
}

@media (max-width: 992px) {
  .content{
    margin-top: 40px;
  }

  h1{
    font-size: 24pt;
  }

  h2{
    font-size: 20pt;
  }

  h3{
    font-size: 18pt;
  }

  h4{
    font-size: 16pt;
  }
}

@media (max-width: 768px) {

  .reg-progress-outer {
    height: 25px;

    .reg-progress-inner {
      padding-top: 2px;
    }
  }

  .webform-grid{

    th.webform-grid-question,
    th.webform-grid-option{
      display: none;
    }

    td.webform-grid-question{
      display: block;
      clear: left;
      float: left;
      width: 100%;
      white-space: pre-wrap !important;
    }

    td.checkbox.webform-grid-option{
      display: inline-block;
      float: left;
      margin-top: -5px;
      padding: 10px 20px;
    }

    td.checkbox.webform-grid-option:before{
      content: attr(data-label);
      margin-left: 2px;
      font-weight: 600;
    }
  }
}

@media (max-width: 440px) {

  .webform-grid{
    td.checkbox.webform-grid-option{
      padding: 10px;
    }
  }
}
