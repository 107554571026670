/**
 * Variable Overrides.
 *
 * Modify this file to override the Bootstrap Framework variables. You can copy
 * these variables directly from ../bootstrap/less/variables.less to this file.
 */

// Set the proper directory for the Bootstrap Glyphicon font.
$icon-font-path: '../bootstrap/fonts/';


$first-base:             #991b1e;
$second-base:            #d87400;
$second-base-light:      #e0b900;
$second-base-lighter:    #f3e399;

$gray-light:             #606a71;
$gray-more-light:        #dfe4e7;
$gray-lighter:           #f4f5f6;
$white:                  #fff;
$black:                  #000;

$body-bg: $white;

$blue: #2f6185;

$text-color:            $black;
$link-color:            $blue;
$link-hover-color:      darken($link-color, 15%);

$font-family-sans-serif:  'Open Sans', Helvetica, sans-serif;
$font-family-awesome:  'FontAwesome';


$font-family-base:        $font-family-sans-serif;

$font-size-base:          10.5pt;
$font-size-large:         ceil(($font-size-base * 1.25));
$font-size-small:         ceil(($font-size-base * 0.85));
$font-size-h1:            floor(($font-size-base * 3.2));
$font-size-h2:            floor(($font-size-base * 2));
$font-size-h3:            ceil(($font-size-base * 1.8));
$font-size-h4:            ceil(($font-size-base * 1.5));
$font-size-h5:            ceil(($font-size-base * 1.3));


$grid-gutter-width:         40px;
$screen-xlg:                1200px;
$screen-xlg-min:            $screen-xlg;
$screen-xlg-desktop:        $screen-xlg-min;
$screen-xlg-max:            ($screen-xlg-min - 1);
$container-xlarge-desktop:  (1140px + $grid-gutter-width);
$container-xlg:             $container-xlarge-desktop;