#block-olaora-notifications-notifications-block {
  a {
    font-weight: normal;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .notifications-unread,
  .notifications-read {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;

    &:hover {
      .label-wrapper {
        color: $black;
      }
    }
  }

  .label-wrapper {
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    i {
      font-size: 22px;
      display: inline-block;
    }
    span {
      display: inline-block;
      margin-left: 5px;
      margin-top: 2px;
    }
  }

  .count-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    @include border-radius(50%);
    margin-left: 5px;

    &.count-sm {
      @include square(20px);
    }
    &.count-md {
      @include square(23px);
    }
    &.count-lg {
      @include square(27px);
    }
  }

  .notifications-count {
    color: $white;
  }

  .notifications-unread {
    .label-wrapper {
      color: #555;
    }
    .count-wrapper {
      background-color: $second-base;
    }
  }

  .notifications-read {
    .label-wrapper {
      color: #ddd;
    }
    .count-wrapper {
      background-color: #ddd;
    }

    &:hover {
      .label-wrapper {
        color: $black;
      }
      .count-wrapper {
        background-color: $black;
      }
    }
  }
}
