.sf-menu {
  float: left;
  margin-bottom: 1em;
  padding: 0;
}
.sf-menu.sf-navbar {
  width: 100%;
}
.sf-menu ul {
  padding-left: 0;
}
.sf-menu a,
.sf-menu a:visited,
.sf-menu span.nolink {
  color: $white;
  padding: 0.75em 0.75em;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 9pt;
}
.sf-menu a.sf-with-ul,
.sf-menu span.nolink.sf-with-ul {
  padding-right: 2.25em;
}
.sf-menu li ul,
.sf-menu li li ul {
  padding-right: 2.25em;
}
.sf-menu.rtl a.sf-with-ul,
.sf-menu.rtl span.nolink.sf-with-ul {
  padding-left: 2.25em;
  padding-right: 1em;
}
.sf-menu span.sf-description {
  color: $white;
  display: block;
  font-size: 0.833em;
  line-height: 1.5;
  margin: 5px 0 0 5px;
  padding: 0;
}
.sf-menu li li a,
.sf-menu li li a:visited,
.sf-menu li li span.nolink {
  color: $black;
}
.sf-menu.rtl span.sf-description {
  margin: 5px 5px 0 0;
}
.sf-menu li,
.sf-menu.sf-navbar {
  background: $second-base;
}
.sf-menu li li {
  background: $second-base-light;
}
.sf-menu li li li {
  background: $second-base-light;
}
.sf-menu li:hover,
.sf-menu li.sfHover,
.sf-menu a:focus,
.sf-menu a:hover,
.sf-menu a:active,
.sf-menu span.nolink:hover {
  background: $second-base-light;
  color: $first-base;
  text-decoration: none;
}
.sf-menu li li:hover,
.sf-menu li li.sfHover,
.sf-menu li li a:focus,
.sf-menu li li a:hover,
.sf-menu li li a:active,
.sf-menu li li span.nolink:hover{
  background: $second-base-lighter;
  outline: 0;
  color: $first-base;
}
div.sf-accordion-toggle a {
  background: $second-base;
  color: $white;
  padding: 1em;
}
div.sf-accordion-toggle a.sf-expanded,
.sf-menu.sf-accordion li.sf-expanded {
  background-color: $second-base-light;
}
div.sf-accordion-toggle a.sf-expanded,
.sf-menu.sf-accordion li.sf-expanded > a,
.sf-menu.sf-accordion li.sf-expanded > span.nolink {
  font-weight: bold;
}
.sf-menu.sf-accordion li a.sf-accordion-button {
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 499;
}
.sf-menu.sf-accordion li li a,
.sf-menu.sf-accordion li li span.nolink {
  padding-left: 2em;
}
.sf-menu.sf-accordion li li li a,
.sf-menu.sf-accordion li li li span.nolink {
  padding-left: 3em;
}
.sf-menu.sf-accordion li li li li a,
.sf-menu.sf-accordion li li li li span.nolink {
  padding-left: 4em;
}
.sf-menu.sf-accordion li li li li li a,
.sf-menu.sf-accordion li li li li li span.nolink {
  padding-left: 5em;
}
.sf-menu.rtl.sf-accordion li li a,
.sf-menu.rtl.sf-accordion li li span.nolink {
  padding-left: auto;
  padding-right: 2em;
}
.sf-menu.rtl.sf-accordion li li li a,
.sf-menu.rtl.sf-accordion li li li span.nolink {
  padding-left: auto;
  padding-right: 3em;
}
.sf-menu.rtl.sf-accordion li li li li a,
.sf-menu.rtl.sf-accordion li li li li span.nolink {
  padding-left: auto;
  padding-right: 4em;
}
.sf-menu.rtl.sf-accordion li li li li li a,
.sf-menu.rtl.sf-accordion li li li li li span.nolink {
  padding-left: auto;
  padding-right: 5em;
}
.sf-menu ul.sf-megamenu li.sf-megamenu-wrapper ol,
.sf-menu ul.sf-megamenu li.sf-megamenu-wrapper ol li {
  margin: 0;
  padding: 0;
}
.sf-menu ul.sf-megamenu li.sf-megamenu-wrapper a.menuparent,
.sf-menu ul.sf-megamenu li.sf-megamenu-wrapper span.nolink.menuparent {
  font-weight: bold;
}
.sf-menu ul.sf-megamenu li.sf-megamenu-wrapper ol li.sf-megamenu-column {
  display: inline;
  float: left;
}
.sf-menu.rtl ul.sf-megamenu li.sf-megamenu-wrapper ol li.sf-megamenu-column {
  float: right;
}

#block-superfish-1 .sf-accordion-toggle {
  display:none;
}

#superfish-1-accordion {
  left:auto !important;
  top:auto !important;
  display: block;
  position: relative;
 }

// Add icon before menu
.sf-menu li{
  a:before,
  span.nolink:before {
    content: "";
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  a#logout-menu:before,
  a#registration-menu:before,
  span#settings-menu:before {
    width: 0;
    height: 25px;
  }

  span#hinegaro-menu:before{
    background-image: url("/sites/default/files/title-maori-icon-hinengaro-white.png");
  }

  span#tinana-menu:before{
    background-image: url("/sites/default/files/title-maori-icon-tinana-white.png");
  }

  span#wairau-menu:before{
    background-image: url("/sites/default/files/title-maori-icon-wairua-white.png");
  }

  a#whanau-menu:before{
    background-image: url("/sites/default/files/title-maori-icon-whanau-white.png");
  }

  a#te-hapai-oranga-menu:before{
    background-image: url("/sites/default/files/title-maori-icon-tehapaioranga-white.png");
  }

  a#pasifika-journey-menu:before{
    background-image: url("/sites/default/files/title-pasifika-icon-journey-white.png");
  }

  span#pasifika-spiritual-menu:before{
    background-image: url("/sites/default/files/title-pasifika-icon-spiritual-white.png");
  }

  span#pasifika-physical-menu:before{
    background-image: url("/sites/default/files/title-pasifika-icon-physical-white.png");
  }

  a#pasifika-family-menu:before{
    background-image: url("/sites/default/files/title-pasifika-icon-family-white.png");
  }

  span#pasifika-mental-menu:before{
    background-image: url("/sites/default/files/title-pasifika-icon-mental-white.png");
  }

  span#pasifika-other-menu:before{
    background-image: url("/sites/default/files/title-pasifika-icon-other-white.png");
  }

  a#notifications-menu:before{
    background-image: url("/sites/default/files/notifications_white.png");
  }

  // sub-menu icons
  a#current-rewards-menu:before, a#showcase-menu:before,
  a#pasifika-current-rewards-menu:before, a#pasifika-showcase-menu:before{
    background-image: url("/sites/default/files/title-icon-reward.png");
  }
  span#wero-menu:before,
  span#whakapakari-menu:before,
  span#wai-kai-menu:before{
    background-image: url("/sites/default/files/title-icon-tinana.png");
  }
  span#goal-menu:before,
  span#move-more-menu:before,
  span#food-menu:before,
  span#tracker-menu:before{
    background-image: url("/sites/default/files/title-icon-physical.png");
  }
  a#my-wero-menu:before,
  a#pasifika-my-goal-menu:before{
    background-image: url("/sites/default/files/title-icon-my-goal.png");
  }
  a#create-new-wero-menu:before,
  a#search-wero-menu:before{
    background-image: url("/sites/default/files/title-icon-goal.png");
  }
  a#pasifika-create-new-goal-menu:before{
    background-image: url("/sites/default/files/title-icon-new-goal.png");
  }
  a#pasifika-search-goal-menu:before{
    background-image: url("/sites/default/files/title-icon-search-goal.png");
  }
  a#mahi-tracker-menu:before, a#steps-tracker-menu:before,
  a#pasifika-activity-tracker-menu:before, a#pasifika-steps-tracker-menu:before{
    background-image: url("/sites/default/files/title-icon-activity-tracker.png");
  }
  a#manawatoa-menu:before,
  a#snacktivity-menu:before{
    background-image: url("/sites/default/files/title-icon-manawatoa.png");
  }
  a#mahi-ngatahi-menu:before,
  a#active-together-menu:before{
    background-image: url("/sites/default/files/title-icon-active-together.png");
  }
  a#activities-menu:before,
  a#pasifika-activities-menu:before{
    background-image: url("/sites/default/files/title-icon-activity.png");
  }
  a#recipe-menu:before,
  a#pasifika-recipe-menu:before{
    background-image: url("/sites/default/files/title-icon-recipe.png");
  }
  a#atua-menu:before{
    background-image: url("/sites/default/files/title-icon-atua.png");
  }
  a#fresh-produce-menu:before{
    background-image: url("/sites/default/files/title-icon-fresh-produce.png");
  }
  a#food-tracker-menu:before, a#water-tracker-menu:before,
  a#pasifika-food-tracker-menu:before, a#pasifika-water-tracker-menu:before{
    background-image: url("/sites/default/files/title-icon-food-tracker.png");
  }
  a#messages-menu:before,
  a#pasifika-messages-menu:before{
    background-image: url("/sites/default/files/title-icon-message.png");
  }
  a#support-service-menu:before,
  a#pasifika-support-service-menu:before{
    background-image: url("/sites/default/files/title-icon-support.png");
  }
  span#karakia-menu:before,
  a#karakia-search-menu:before,
  a#create-new-karakia-menu:before{
    background-image: url("/sites/default/files/title-maori-icon-wairua.png");
  }
  span#pasifika-blessing-menu:before, 
  a#pasifika-create-new-blessing-menu:before,
  a#pasifika-search-blessing-menu:before, {
    background-image: url("/sites/default/files/title-pasifika-icon-spiritual.png");
  }
  a#add-friends-menu:before, a#group-for-friends-menu:before, a#my-groups-menu:before{
    background-image: url("/sites/default/files/title-maori-icon-whanau.png");
  }
  a#pasifika-add-friends-menu:before, a#pasifika-group-for-friends-menu:before, a#pasifika-my-groups-menu:before{
    background-image: url("/sites/default/files/title-pasifika-icon-family.png");
  }
  a#wai-maori-ngai-maori-menu:before{
    background-image: url("/sites/default/files/title-maori-icon-waimaori.png");
  }
}

/* CSS for aligning the Main Menu in the Center */

#block-superfish-1{
  text-align: center;

  .sf-menu{
    float: none;

    li{
      float: none;
      display: inline-block;
      text-align: left;
    }
  }

}
